<template>
  <b-card no-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(aciklama)="data">
        {{ data.item.aciklama }}
        <div class="text-primary font-small-2">
          {{ moment(data.item.tescil_tarihi).format('DD.MM.YYYY') }}
        </div>
        <div class="text-warning font-small-2">
          {{ data.item.sase_no }}
        </div>
        <div class="text-muted font-small-2">
          {{ data.item.plaka }}
        </div>
      </template>
      <template #cell(stock_status)="data">
        <b-badge :variant="data.item.status_variant">
          {{ data.item.stock_status }}
        </b-badge>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-dropdown
            variant="primary"
            text="Stok Ekle"
            left
            split
            size="sm"
            @click="$router.push('/rental/stocks/add/' + data.item.arac_kodu)"
          >
            <b-dropdown-item @click="hiddenLogoStock(data.item.arac_kodu)">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Sil</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BTable, BPagination, BCardFooter, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BPagination,
    BCardFooter,
    BBadge,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'arac_kodu',
          label: 'ARAÇ KODU',
          thClass: 'width-150',
        },
        {
          key: 'aciklama',
          label: 'ARAÇ BİLGİSİ',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '200px' },
        },
      ],
      dataQuery: {
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['rentalStocks/waitDataList']
    },
    dataCounts() {
      return this.$store.getters['rentalStocks/waitDataCounts']
    },
    saveData() {
      return this.$store.getters['rentalStocks/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('rentalStocks/getWaitDataList', this.dataQuery)
    },
    hiddenLogoStock(aracKodu) {
      this.$store.dispatch('rentalStocks/hiddenLogoStock', aracKodu)
        .then(res => {
          if (res.status) {
            this.getDataList()
          }
        })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
